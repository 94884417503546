import React, { lazy }  from "react";
import App from '../App';
import {
    createBrowserRouter,
    IndexRouteObject,
    NonIndexRouteObject,
    RouteObject,
    RouterProvider,
} from 'react-router-dom';
import {RouteInfoType} from '../types/types';

const AccessesPage = lazy(() => import('../components/accesses/accesses'));
const InvatatationsPage = lazy(() => import('../pages/invatations'));
const AlicePage = lazy(() => import('../components/alice/alice'));

const accesses = {
    id: 'accesses',
    path: '/share/access',
    name: 'Доступы',
    hide: true,
    component: <AccessesPage />,
} as RouteInfoType;

const alice = {
    id: 'alice',
    path: '/:device/oauth',
    name: 'Доступы алиса',
    hide: true,
    component: <AlicePage />,
} as RouteInfoType;

const invitations = {
    id: 'invitations',
    path: '/invitations/app',
    name: 'Приглашение',
    hide: true,
    component: <InvatatationsPage />,
} as RouteInfoType;

const renderRouteInfoType = (routeItem: RouteInfoType): RouteObject => {
    return {
            path: routeItem.path,
            element: routeItem.component,
            index: routeItem.index,
        } as NonIndexRouteObject | IndexRouteObject;
};

const convertRoutesNew = () => {
    return [
        renderRouteInfoType(accesses),
        renderRouteInfoType(alice),
        renderRouteInfoType(invitations),
    ];
};

function RouterConfig() {
    return (
        <React.StrictMode>
            <App>
                <RouterProvider router={createBrowserRouter(convertRoutesNew())} />
            </App>
        </React.StrictMode>
    )
}

export default RouterConfig;
